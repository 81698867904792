import { Nullish, Nullable } from '@tager/web-core';

type ThumbnailType = {
  readonly url: Nullable<string>;
  readonly url_2x?: Nullable<string>;
  readonly url_webp?: Nullable<string>;
  readonly url_webp_2x?: Nullable<string>;
  readonly thumbnails?: {
    readonly url?: Nullable<string>;
    readonly url_2x?: Nullable<string>;
    readonly url_webp?: Nullable<string>;
    readonly url_webp_2x?: Nullable<string>;
  };
};

interface PictureImageType {
  src?: Nullish<string>;
  src2x?: Nullish<string>;
  webp?: Nullish<string>;
  webp2x?: Nullish<string>;
}

export function convertThumbnailToPictureImage(
  image: Nullish<ThumbnailType>
): PictureImageType | undefined {
  if (!image) return undefined;

  return {
    src: image.thumbnails?.url_webp ?? image.thumbnails?.url ?? image.url,
    src2x: image.thumbnails?.url_webp ?? image.thumbnails?.url ?? image.url_2x,
    webp: image.thumbnails?.url_webp ?? image.thumbnails?.url ?? image.url_webp,
    webp2x:
      image.thumbnails?.url_webp ?? image.thumbnails?.url ?? image.url_webp_2x,
  };
}

export function convertThumbnailToModalPictureImage(
  image: Nullish<ThumbnailType>
): PictureImageType | undefined {
  if (!image) return undefined;

  return {
    src: image.thumbnails?.url_webp_2x ?? image.thumbnails?.url_2x ?? image.url,
    src2x: null,
    webp:
      image.thumbnails?.url_webp_2x ?? image.thumbnails?.url_2x ?? image.url,
    webp2x: null,
  };
}
