import React from 'react';
import styled, { css } from 'styled-components';

import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import SeeAlsoItem from '@/modules/Search/components/SeeAlso/components/SeeAlsoItem';
import { media } from '@/utils/mixin';

function SeeAlso() {
  const headerMenuMain =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'main')) ?? [];

  return (
    <Component>
      <Inner>
        <Top>
          <Title>Смотрите так же</Title>
        </Top>
        <List>
          {headerMenuMain?.map((item, index) => {
            return (
              <Cell key={index}>
                <SeeAlsoItem seeAlsoItem={item} />
              </Cell>
            );
          })}
        </List>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  margin-bottom: 212px;

  ${media.laptop(css`
    margin-bottom: 160px;
  `)}

  ${media.tablet(css`
    margin-bottom: 32px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 50px;
  `)}
`;

const Inner = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Top = styled.div`
  margin-top: 80px;

  ${media.laptop(css`
    margin-top: 48px;
  `)}

  ${media.tablet(css`
    margin-top: 30px;
  `)}

  ${media.mobile(css`
    margin-top: 50px;
  `)}
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 36px -16px -40px;

  ${media.laptop(css`
    margin: 54px -12px -25px;
  `)}

  ${media.tablet(css`
    margin: 30px -18px 0;
  `)}
  
  ${media.mobile(css`
    display: block;
    margin: -2px 0 -30px;
  `)}
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 50px;
  line-height: 58px;

  ${media.laptop(css`
    font-size: 48px;
    line-height: 56px;
  `)}

  ${media.tablet(css`
    font-size: 32px;
    line-height: 38px;
  `)}
  
  ${media.mobile(css`
    font-size: 28px;
    line-height: 34px;
  `)}
`;

const Cell = styled.div`
  flex: 1 1 33.33%;
  max-width: 33.33%;
  padding: 43px 16px;

  ${media.laptop(css`
    padding: 25px 12px;
  `)}

  ${media.tablet(css`
    flex: 1 1 50%;
    max-width: 50%;
    padding: 0 18px;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
    padding: 0;

    &:first-child {
      & div {
        border: none;
      }
    }
  `)}
`;

export default SeeAlso;
