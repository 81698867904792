import React, { useState } from 'react';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { convertRequestErrorToMap } from '@tager/web-core';

import { sendSubscribeForm, SubscribeFormPayload } from '@/services/requests';

import SubscribeForm, { SubscribeFormValues } from './SubscribeForm';

function SubscribeFormContainer() {
  const [cityId, setCityId] = useState<number | null>(null);
  const [isSentSuccess, setSentSuccess] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [isClearValue, setClearValue] = useState<boolean>(false);

  function handleSubmit(
    values: SubscribeFormValues,
    formikHelpers: FormikHelpers<SubscribeFormValues>
  ) {
    const payload: SubscribeFormPayload = {
      email: values.email,
      phone: values.phone,
      cityId: cityId,
    };
    setDisabled(true);
    sendSubscribeForm(payload)
      .then(() => {
        formikHelpers.resetForm();
        setClearValue(true);
        setSentSuccess(true);
        setTimeout(() => setSentSuccess(false), 5000);
      })
      .catch((error) => {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<SubscribeFormValues>;
        formikHelpers.setErrors(errorMap);
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
        setDisabled(false);
      });
  }

  return (
    <Formik<SubscribeFormValues>
      onSubmit={handleSubmit}
      initialValues={{ email: '', phone: '', cityId: null }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(formProps) => (
        <SubscribeForm
          {...formProps}
          isSentSuccess={isSentSuccess}
          isDisabled={isDisabled}
          setCityId={(value: number) => setCityId(value)}
          cityId={cityId}
          isClearValue={isClearValue}
        />
      )}
    </Formik>
  );
}

export default SubscribeFormContainer;
