import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Form, FormikProps } from 'formik';
import { FormikErrors } from 'formik/dist/types';

import { media } from '@/utils/mixin';
import { colors, fonts } from '@/constants/theme';
import useSettingItem from '@/hooks/useSettingItem';
import Button from '@/components/Button';
import Spinner from '@/components/Spinner';
import { TextInputFormik } from '@/components/TextInput';
import { MaskedTextInputFormik } from '@/components/MaskedTextInput';
import { SubscribeFormPayload } from '@/services/requests';
import SelectComponent from '@/components/Select';
import { useTypedSelector } from '@/store/store';
import { selectVacancyByCitiesList } from '@/store/reducers/vacanciesCities';
import Checkbox from '@/components/Checkbox';
import FormControl from '@/components/FormControl';

import MessageSuccess from './MessageSuccess';

export type SubscribeFormValues = SubscribeFormPayload;

type Props = FormikProps<SubscribeFormValues> & {
  isSentSuccess: boolean;
  isDisabled: boolean;
  setCityId: (value: number) => void;
  cityId: number | null;
  isClearValue: boolean;
};

function SubscribeForm({
  isSentSuccess,
  isSubmitting,
  isDisabled,
  setCityId,
  cityId,
  isClearValue,
  submitForm,
}: Props) {
  const vacancyByCitiesList = useTypedSelector(selectVacancyByCitiesList);

  const subscriptionTitle = useSettingItem('SUBSCRIPTION_TITLE');
  const subscriptionText = useSettingItem('SUBSCRIPTION_TEXT');
  const subscriptionBottomText = useSettingItem('SUBSCRIPTION_BOTTOM_TEXT');
  const subscriptionButtonLabel = useSettingItem('SUBSCRIPTION_BUTTON_LABEL');
  const formCheckboxes = useSettingItem('SUBSCRIPTION_CHECKBOXES');

  const [clientSideValidationErrors, setClientSideValidationErrors] =
    useState<FormikErrors<{ isRequiredCheckboxesChecked: string }>>();

  const [checkboxValues, setCheckboxValues] = useState<Array<boolean>>(
    formCheckboxes.map(() => false)
  );

  function submit(event: BaseSyntheticEvent) {
    event.preventDefault();

    setClientSideValidationErrors({});

    const isRequiredCheckboxesChecked =
      checkboxValues.filter(
        (checkbox, index) => formCheckboxes[index].required && checkbox
      ).length ===
      formCheckboxes.filter((checkbox) => checkbox.required).length;

    if (isRequiredCheckboxesChecked) {
      submitForm().then();
    } else {
      setClientSideValidationErrors({
        isRequiredCheckboxesChecked:
          'Чтобы продолжить, необходимо принять соглашение',
      });
    }
  }

  return (
    <Form autoComplete="off" noValidate>
      <FormContent>
        <MessageSuccess isSentSuccess={isSentSuccess} />
        <FormInner isSentSuccess={isSentSuccess}>
          <Top>
            <Title>{subscriptionTitle}</Title>
            <Text>{subscriptionText}</Text>
          </Top>

          <Center>
            <FormRow>
              <FormLeft>
                <InputWrapper>
                  <Input
                    type="email"
                    name="email"
                    required
                    placeholder="Ваш email"
                    autoComplete="off"
                    disabled={isDisabled}
                  />
                </InputWrapper>
                <Divider>/</Divider>
                {/* <InputWrapper>
                  <MaskedTextInputFormik
                    name="phone"
                    placeholder="Номер телефона"
                    mask="{+375}(00)000-00-00"
                    clearErrorOnChange
                    required
                    autoComplete="off"
                    disabled={isDisabled}
                    isSentSuccess={isSentSuccess}
                  />
                </InputWrapper>
                <Divider>/</Divider> */}
                <InputWrapper>
                  <SelectComponent
                    optionList={vacancyByCitiesList}
                    activeValue={cityId ?? 0}
                    placeholder={'Город'}
                    onChange={(value: number) => setCityId(value)}
                    isClearValue={isClearValue}
                    isSubscribe={true}
                  />
                </InputWrapper>
              </FormLeft>

              <FormRight>
                <SendButton
                  variant="primary"
                  type="submit"
                  isSubmitting={isSubmitting}
                  onClick={submit}
                >
                  {!isSubmitting ? (
                    subscriptionButtonLabel
                  ) : (
                    <Spinner color="black" absolute={true} />
                  )}
                </SendButton>
              </FormRight>
            </FormRow>
            {!!formCheckboxes.length && (
              <CheckboxWrapper
                error={clientSideValidationErrors?.isRequiredCheckboxesChecked}
              >
                {formCheckboxes.map((checkbox, index) => (
                  <Checkbox
                    key={index}
                    checked={checkboxValues[index]}
                    onChange={() =>
                      setCheckboxValues(
                        checkboxValues.map((checkbox, checkboxIndex) =>
                          checkboxIndex === index ? !checkbox : checkbox
                        )
                      )
                    }
                    label={checkbox.text ?? ''}
                    id={'vacancy-form-checkbox-' + index}
                  />
                ))}
              </CheckboxWrapper>
            )}
          </Center>

          <Bottom>
            <Text>{subscriptionBottomText}</Text>
          </Bottom>
        </FormInner>
      </FormContent>
    </Form>
  );
}

const FormRow = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  ${media.tablet(css`
    flex-direction: column;
    align-items: center;
  `)}

  ${media.mobile(css`
    align-items: flex-start;
  `)}
`;

const FormContent = styled.fieldset`
  border: none;
  position: relative;
  padding: 0;
  width: 100%;
  flex: 1 1 auto;
`;

const FormInner = styled.div<{ isSentSuccess: boolean }>`
  transition: all 0.3s ease-in-out;
  ${(props) =>
    props.isSentSuccess
      ? css`
          opacity: 0;
        `
      : css`
          opacity: 1;
        `}
`;

const FormLeft = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;

  ${media.tablet(css`
    width: 100%;
  `)}

  ${media.mobile(css`
    display: block;
  `)}
`;

const FormRight = styled.div`
  margin-left: 32px;

  ${media.laptop(css`
    margin-left: 53px;
  `)}

  ${media.tablet(css`
    margin: 50px 0 0;
  `)}
`;

const InputWrapper = styled.div`
  flex: 1 1 33%;
  position: relative;

  input {
    border: none;
    border-bottom: 4px solid ${colors.black};
    padding: 18px 0;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: ${colors.black};

    ${media.laptop(css`
      height: 50px;
      border-bottom: 2px solid ${colors.black};
      font-size: 24px;
      line-height: 32px;
      padding: 9px 0;
    `)}

    ${media.tablet(css`
      font-size: 20px;
      line-height: 24px;
      padding: 4px 0;
      height: 34px;
    `)}
    
    ${media.mobile(css`
      font-size: 16px;
      line-height: 20px;
    `)}

    &::placeholder {
      opacity: 0.4;
      color: ${colors.black};

      ${media.laptop(css`
        font-size: 24px;
        line-height: 32px;
      `)}

      ${media.tablet(css`
        font-size: 20px;
        line-height: 24px;
      `)}
      
      ${media.mobile(css`
        font-size: 16px;
        line-height: 20px;
      `)}
    }
  }

  ${media.mobile(css`
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  `)}
`;

const Input = styled(TextInputFormik)``;

const Divider = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 35px;

  font-family: ${fonts.Inter};
  font-size: 84px;
  line-height: 75px;
  color: ${colors.black};

  ${media.laptop(css`
    margin: 0 25px;

    font-size: 56px;
    line-height: 50px;
  `)}

  ${media.tablet(css`
    margin: 0 22px;
    font-size: 35px;
    line-height: 31px;
  `)}
  
  ${media.mobile(css`
    display: none;
  `)}
`;

const SendButton = styled(Button)<{ isSubmitting: boolean }>`
  position: relative;
  height: 100%;
  border: 4px solid ${colors.black};
  min-width: 377px;
  padding: 20px 35px;

  font-weight: 500;
  font-size: 20px;
  line-height: 26px;

  ${media.laptop(css`
    border: 2px solid ${colors.black};
    min-width: 247px;
    padding: 7px 35px;

    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.tablet(css`
    min-height: 38px;
    padding: 9px 36px;
    min-width: 185px;
    font-size: 16px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    min-height: 50px;
    min-width: 247px;
    line-height: 24px;
  `)}

  ${(props) =>
    props.isSubmitting
      ? css`
          border: none;
        `
      : css``}
`;

const Top = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;

  ${media.laptop(css`
    max-width: 740px;
  `)}

  ${media.tablet(css`
    max-width: 658px;
  `)}
  
  ${media.mobile(css`
    text-align: left;
  `)}
`;

const Center = styled.div`
  margin-top: 95px;

  ${media.laptop(css`
    margin-top: 60px;
  `)}

  ${media.tablet(css`
    margin-top: 50px;
  `)}
  
  ${media.mobile(css`
    margin-top: 60px;
  `)}
`;

const Bottom = styled.div`
  margin-top: 80px;
  text-align: center;

  ${media.laptop(css`
    margin-top: 53px;
  `)}

  ${media.tablet(css`
    max-width: 658px;
    margin: 50px auto 0;
  `)}
  
  ${media.mobile(css`
    margin-top: 30px;
    text-align: left;
  `)}
`;

const Title = styled.span`
  display: block;
  padding: 0;
  margin: 0 0 38px;
  font-weight: 500;
  font-size: 64px;
  line-height: 72px;
  color: ${colors.black};

  ${media.laptop(css`
    margin-bottom: 16px;
    font-size: 48px;
    line-height: 56px;
  `)}

  ${media.tablet(css`
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 20px;
  `)}
  
  ${media.mobile(css`
    font-size: 28px;
    line-height: 34px;
  `)}
`;

const Text = styled.span`
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const CheckboxWrapper = styled(FormControl)`
  margin-top: 20px;

  span {
    right: auto;
  }
`;

export default SubscribeForm;
