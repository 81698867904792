import React from 'react';
import styled, { css } from 'styled-components';

import { ThumbnailType } from '@tager/web-modules';
import { Nullable } from '@tager/web-core';

import { StringField } from '@/typings/model';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Star from '@/components/Sticker/Star';

import SectionPreview from '../SectionPreview';

import ShopCard from './components/ShopCard';

type Props = {
  bannerTitle: StringField;
  bannerButtonLabel: StringField;
  bannerButtonUrl: StringField;
  bannerImage: Nullable<ThumbnailType>;
  bannerStickerLabel: StringField;
  bannerStickerUrl: StringField;
};

function Shops({
  bannerButtonLabel,
  bannerTitle,
  bannerButtonUrl,
  bannerImage,
  bannerStickerLabel,
  bannerStickerUrl,
}: Props) {
  return (
    <Component>
      <ShopsContainer>
        <ShopCard image={bannerImage}>
          <ShopContent>
            <SectionPreview
              title={bannerTitle}
              buttonLabel={bannerButtonLabel}
              buttonUrl={bannerButtonUrl}
              isDiscount={true}
            />
          </ShopContent>
        </ShopCard>
      </ShopsContainer>
      <StarWrapper>
        <Star
          color="lime"
          size="big"
          rotate={'-10deg'}
          label={bannerStickerLabel ?? ''}
          link={bannerStickerUrl ?? ''}
        />
      </StarWrapper>
    </Component>
  );
}

const Component = styled.div`
  background: ${colors.black};
  position: relative;
  z-index: 3;
`;

const ShopsContainer = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`;

const ShopContent = styled.div`
  max-width: 524px;
  margin: 0 auto;
  ${media.laptop(css`
    max-width: 412px;
  `)}

  ${media.tablet(css`
    max-width: 346px;
    margin: 0 auto;
  `)}
  
  ${media.tabletSmall(css`
    margin: 0 36px -10px;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
    margin: 0 20px;
  `)}
`;

const StarWrapper = styled.div`
  position: absolute;
  bottom: -208px;
  left: 24px;
  z-index: 30;

  width: 316px;
  height: 316px;
  transform: rotate(-5deg);

  ${media.laptop(css`
    bottom: -132px;
    left: 40px;

    width: 240px;
    height: 240px;
  `)}

  ${media.tabletSmall(css`
    bottom: -138px;
    left: 31px;

    width: 180px;
    height: 180px;
    transform: rotate(-21deg);
  `)}
  
  ${media.mobile(css`
    bottom: -16px;
    left: -5px;

    width: 164px;
    height: 164px;
    transform: rotate(-6deg);
  `)}
`;

export default Shops;
