import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { StringField } from '@/typings/model';
import { ButtonLink } from '@/components/Button';
import { colors } from '@/constants/theme';
import { ReactComponent as ArrowRight } from '@/assets/svg/arrow-right.svg';
import discountUrl from '@/assets/images/shops/discount.png';
import discountUrlLaptop from '@/assets/images/shops/discount-laptop.png';
import discountUrlTablet from '@/assets/images/shops/discount-tablet.png';
import discountUrlMobile from '@/assets/images/shops/discount-mobile.png';
import Link from '@/components/Link';

interface Props {
  title: StringField;
  buttonLabel: StringField;
  buttonUrl: StringField;
  isBlackTheme?: boolean;
  isDiscount?: boolean;
}

const SectionPreview = forwardRef<HTMLDivElement, Props>(
  function SectionPreview(props, ref) {
    const { title, buttonLabel, buttonUrl, isBlackTheme, isDiscount } = props;
    return (
      <Component ref={ref}>
        <Top>
          <Title
            isBlackTheme={isBlackTheme}
            isDiscount={isDiscount}
            dangerouslySetInnerHTML={{ __html: title ?? '' }}
          />
        </Top>
        <Bottom>
          <MoreBtn to={buttonUrl ?? '#'}>
            {buttonLabel}
            <ArrowRight />
          </MoreBtn>
        </Bottom>
      </Component>
    );
  }
);

const Component = styled.div``;

const Top = styled.div`
  margin-bottom: 52px;

  ${media.laptop(css`
    margin-bottom: 32px;
  `)}

  ${media.tabletSmall(css`
    margin-bottom: 30px;
  `)}
`;

const Bottom = styled.div``;

const Title = styled.span<{ isBlackTheme?: boolean; isDiscount?: boolean }>`
  font-weight: 500;
  font-size: 64px;
  line-height: 72px;
  word-wrap: break-word;
  color: ${(props) =>
    props.isBlackTheme ? `${colors.black}` : `${colors.white}`};

  b {
    font-weight: 500;
    color: ${(props) =>
      props.isBlackTheme ? `${colors.orange}` : `${colors.yellow100}`};

    ${(props) =>
      props.isDiscount
        ? css`
            color: ${colors.black};
            position: relative;
            display: inline-block;
            z-index: 1;

            &:after {
              position: absolute;
              content: url(${discountUrl});
              top: 2px;
              left: -40px;
              height: 100%;
              z-index: -1;

              ${media.laptop(css`
                content: url(${discountUrlLaptop});
                top: 4px;
                left: -32px;
              `)}

              ${media.tablet(css`
                content: url(${discountUrlTablet});
                max-height: 40px;
                top: 0;
                left: -18px;
              `)}     
              
              ${media.mobile(css`
                content: url(${discountUrlMobile});
                max-height: 36px;
                top: 0;
                left: -11px;
              `)}
            }
          `
        : ''}
  }

  ${media.laptop(css`
    font-size: 48px;
    line-height: 56px;
  `)}

  ${media.tablet(css`
    font-size: 32px;
    line-height: 38px;
  `)}
  
  ${media.mobile(css`
    font-size: 28px;
    line-height: 34px;
  `)}
`;

const MoreBtn = styled(Link)`
  display: inline-block;
  position: relative;
  left: -9px;
  padding: 18px 58px 18px 30px;
  background: ${colors.yellow100};
  color: ${colors.black};
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  clip-path: polygon(4% 12%, 95% 0%, 95% 88%, 4% 100%);
  transition: all 0.3s linear;

  &:hover {
    color: ${colors.orange};
    // svg {
    //   transform: translateX(5px) translateY(-50%);
    // }
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 32px;
    width: 18px;
    height: 15px;
    //transition: all 0.3s linear;
  }

  ${media.laptop(css`
    left: 0;
    top: 1px;
    padding: 16px 41px 16px 12px;
    font-size: 16px;
    line-height: 24px;
    clip-path: polygon(0% 14%, 100% 0%, 100% 84%, 0% 100%);

    svg {
      right: 14px;
    }
  `)}
`;

const DiscountBackground = styled.div``;

export default SectionPreview;
