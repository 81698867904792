import React from 'react';
import styled, { css } from 'styled-components';

import {
  convertThumbnailToPictureImage,
  ThumbnailType,
} from '@tager/web-modules';
import { Nullable } from '@tager/web-core';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  image?: Nullable<ThumbnailType>;
  children?: React.ReactNode;
};

function ShopCard({ children, image }: Props) {
  return (
    <Component>
      <Inner>
        <Left>
          <ShopImage>
            {image ? (
              <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
            ) : null}
          </ShopImage>
        </Left>
        <Right>
          <ShopDescription>{children}</ShopDescription>
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  background: ${colors.black};

  ${media.tablet(css`
    background: transparent;
  `)}
`;

const Inner = styled.div`
  display: flex;

  ${media.mobile(css`
    flex-direction: column-reverse;
  `)}
`;

const Left = styled.div`
  flex: 1 1 50%;
  max-width: 50%;

  ${media.tablet(css`
    flex: 1 1 57%;
    max-width: 57%;
  `)}

  ${media.mobile(css`
    flex: 1 1 auto;
    max-width: 100%;
  `)}
`;
const Right = styled.div`
  flex: 1 1 auto;

  ${media.tablet(css`
    background: ${colors.black};
    padding: 30px 0;
  `)}

  ${media.mobile(css`
    padding: 110px 0;
  `)}
`;

const ShopImage = styled.div`
  position: relative;
  background: ${colors.gray50};
  height: 100%;

  ${media.tablet(css`
    background: ${colors.black};
    border-radius: 24px 0 0 24px;
  `)}

  ${media.mobile(css`
    background: ${colors.black};
    border-radius: 0 0 24px 24px;
  `)}

  &:before {
    display: block;
    content: '';
    padding-bottom: 93.8%;

    ${media.mobile(css`
      padding-bottom: 100%;
    `)}
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    ${media.tablet(css`
      border-radius: 24px;
      overflow: hidden;
    `)}
  }

  img,
  picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const ShopDescription = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export default ShopCard;
