import React from 'react';
import styled, { css } from 'styled-components';

import { MenuItemType } from '@tager/web-modules';

import { ReactComponent as Arrow } from '@/assets/svg/search-arrow.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Link from '@/components/Link';

type Props = {
  seeAlsoItem: MenuItemType;
};

function SeeAlsoItem({ seeAlsoItem }: Props) {
  const { label, link } = seeAlsoItem;
  return (
    <Component>
      <ComponentLink to={link ?? '#'} />
      <Inner>
        <Left>
          <Title>{label}</Title>
        </Left>
        <Right>
          <Arrow />
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  transition: all 0.3s linear;
  height: 100%;
  min-height: 161px;

  ${media.laptop(css`
    min-height: 135px;
  `)}

  ${media.tablet(css`
    min-height: 118px;
  `)}
  
  ${media.mobile(css`
    min-height: auto;
  `)}
  
  &:hover {
    background: ${colors.yellow200};
  }
`;

const Inner = styled.div`
  padding: 38px 0 6px;
  border-top: 1px solid ${colors.grayLight};
  display: flex;
  justify-content: space-between;

  ${media.laptop(css`
    padding: 32px 0 6px;
  `)}

  ${media.tablet(css`
    padding: 26px 0;
  `)}
  
  ${media.mobile(css`
    padding: 30px 0;
  `)}
`;

const Left = styled.div`
  flex: 1 1 400px;
  max-width: 400px;

  ${media.laptop(css`
    flex: 1 1 312px;
    max-width: 312px;
  `)}

  ${media.tablet(css`
    flex: 1 1 250px;
    max-width: 250px;
  `)}
  
  ${media.mobile(css`
    max-width: 100%;
    flex: 1 1 auto;
    margin-right: 20px;
  `)}
`;

const Title = styled.span`
  font-weight: 500;
  font-size: 50px;
  line-height: 58px;

  ${media.laptop(css`
    font-size: 40px;
    line-height: 48px;
  `)}

  ${media.tablet(css`
    font-size: 28px;
    line-height: 34px;
  `)}
  
  ${media.mobile(css`
    font-size: 20px;
    line-height: 48px;
  `)}
`;

const Right = styled.div`
  ${media.mobile(css`
    flex: 0 0 38px;
  `)}

  svg {
    width: 48px;
    height: 48px;

    ${media.tablet(css`
      position: relative;
      top: -5px;
    `)}

    ${media.mobile(css`
      width: 38px;
      height: 38px;
      top: 6px;
    `)}
  }
`;

const ComponentLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export default SeeAlsoItem;
