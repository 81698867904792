import React from 'react';
import styled, { css } from 'styled-components';

import { getSettingValueByKey } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import { selectSettingItemList } from '@/store/reducers/tager/settings';

type Props = {
  isSentSuccess: boolean;
};

function MessageSuccess({ isSentSuccess }: Props) {
  const settingsList = useTypedSelector(selectSettingItemList);

  const messageSuccess = getSettingValueByKey(
    settingsList,
    'SUBSCRIPTION_SUCCESS_MESSAGE'
  );

  return (
    <Message isSentSuccess={isSentSuccess}>
      <Title>{messageSuccess}</Title>
    </Message>
  );
}

const Message = styled.div<{ isSentSuccess: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  ${(props) =>
    props.isSentSuccess
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}
`;

const Title = styled.span`
  display: block;
  font-weight: 500;
  font-size: 64px;
  line-height: 72px;
  color: ${colors.black};
  text-align: center;

  ${media.laptop(css`
    font-size: 48px;
    line-height: 56px;
  `)}
`;

export default MessageSuccess;
