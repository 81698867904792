import React from 'react';
import styled, { css } from 'styled-components';

import useSettingItem from '@/hooks/useSettingItem';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { ReactComponent as ArrowDown } from '@/assets/svg/download-icon.svg';
import Picture from '@/components/Picture';
import { convertThumbnailToModalPictureImage } from '@/modules/Promotions/components/helpers';
import useWindowDimensions from '@/hooks/useWindowDimensions';

type Props = {
  isSettings?: boolean;
  background?: string;
  pdf:
    | {
        pdfTitle: string;
        pdfDescription: string;
        pdfImageFirst: string;
        pdfImageSecond: string;
        pdfLink: string;
        pdfIsEnabled: boolean;
      }
    | any;
};

function DownloadPDF({ isSettings, background, pdf }: Props) {
  const windowSize = useWindowDimensions();
  const enabled = useSettingItem('SUBSCRIPTION_ENABLED');
  return (
    <>
      {windowSize.width > 1260 && (
        <>
          <ImageSecond>
            <Picture
              mobileSmall={convertThumbnailToModalPictureImage(
                pdf.pdfImageSecond
              )}
            />
          </ImageSecond>
          <ImageOne>
            <Picture
              mobileSmall={convertThumbnailToModalPictureImage(
                pdf.pdfImageFirst
              )}
            />
          </ImageOne>
        </>
      )}

      <Component
        isVisible={!!enabled}
        isSettings={isSettings}
        background={background}
      >
        <Inner>
          <Container>
            <Title>{pdf.pdfTitle.toUpperCase()}</Title>
            <Block>
              <Text>{pdf.pdfDescription}</Text>
            </Block>
            <Button href={pdf.pdfLink}>
              <IconDownload>
                <ArrowDown fill={'transparent'} />
              </IconDownload>
              <BtnText>Скачать PDF</BtnText>
            </Button>
          </Container>

          {windowSize.width > 1260 && <ContainerImages></ContainerImages>}
        </Inner>
      </Component>
    </>
  );
}

const Button = styled.a`
  width: 359.81px;
  height: 88px;
  background: #f9683a;
  margin-top: 44px;
  display: inline-flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 26px;
  padding-right: 52px;

  &:hover {
    transition: 0.3s ease-in;
    opacity: 0.9;
  }

  ${media.laptop(css`
    width: 280.81px;
    height: 68px;
    margin-top: 24px;
  `)}

  ${media.tablet(css`
    max-width: 255px;
    height: 62px;
    margin-left: auto;
    margin-right: auto;
    display: flex !important;
  `)}

  ${media.mobile(css`
    margin: 0 auto;
    margin-top: 32px;
    max-width: 202px;
    height: 49px;
    padding-right: 28px;
  `)}
  ${media.mobileSmall(css`
    margin: 0;
    margin-top: 32px;
    width: 186px;
    height: 50px;
    padding-right: 24px;
  `)}
`;

const Container = styled.div`
  max-width: 880px;
  width: 50%;
  margin: 0 auto;
  position: relative;
  display: block;
  //align-content: center;
  //justify-content: center;
  ${media.mobile(css`
    text-align: center;
    width: 100%;
  `)}
  ${media.tabletLarge(css`
    text-align: center;
    width: 85%;
  `)}
  ${media.mobileSmall(css`
    text-align: left;
    width: 85%;
  `)}
`;

const IconDownload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 52px;
  height: 51px;

  ${media.laptop(css`
    width: 36px;
    height: 36px;
  `)}

  ${media.tablet(css`
    width: 32px;
    height: 32px;
  `)}

  ${media.mobile(css`
    width: 24px;
    height: 24px;
  `)}
`;

const ContainerImages = styled.div`
  max-width: 880px;
  width: 50%;
  position: relative;
  align-items: center;
  align-content: center;
  justify-content: center;
  display: inline-flex;
`;
const ImageOne = styled.div`
  max-width: 398.46px;
  min-width: 40px;
  width: 398.46px;
  height: 554.52px;
  z-index: 34;
  position: absolute;
  transform: rotate(-6.23deg);
  right: 455px;
  margin-top: 40px;
  -webkit-box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.25);
  ${media.desktopOnly(css`
    width: 398.46px;
    height: 504.52px;
    right: 415px;
    margin-top: 50px;
  `)}

  ${media.laptop(css`
    width: 308.45px;
    height: 373.59px;
    right: 305px;
    margin-top: 100px;
  `)}


  ${media.tablet(css`
    width: 298.45px;
    height: 373.59px;
    right: 350px;
    margin-top: 110px;
  `)}
`;

const ImageSecond = styled.div`
  max-width: 398.46px;
  min-width: 40px;
  width: 398.46px;
  height: 554.52px;
  z-index: 21;
  position: absolute;
  transform: rotate(8.54deg);
  margin-top: -85px;
  right: 150px;

  ${media.desktopOnly(css`
    width: 348.46px;
    height: 504.52px;
    margin-top: -30px;
    right: 150px;
  `)}

  ${media.laptop(css`
    width: 300.45px;
    height: 373.59px;
    margin-top: -40px;
    right: 100px;
  `)}
  
  
  ${media.tablet(css`
    width: 268.45px;
    height: 373.59px;
    right: 150px;
    margin-top: -40px;
  `)}
`;
const Title = styled.span`
  display: block;
  padding: 0;
  font-weight: 700;
  font-size: 48px;
  line-height: 46px;
  //margin-bottom: 55px;

  color: ${colors.white};

  ${media.laptop(css`
    font-size: 36px;
    line-height: 46px;
  `)}

  ${media.tablet(css`
    font-size: 36px;
    line-height: 46px;
  `)}

  ${media.mobile(css`
    font-size: 22px;
    line-height: 46px;
  `)}
`;

const Block = styled.div`
  display: inline-block;

  margin-top: 42px;
  //margin-bottom: 20px;
  ${media.tablet(css`
    margin-top: 12px;
    margin-bottom: 20px;
  `)}
  ${media.tabletLarge(css`
    text-align: center;
    width: 100%;
  `)}
  ${media.mobileSmall(css`
    text-align: left;
    width: 85%;
  `)}
`;

const Text = styled.span`
  font-size: 30px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  color: ${colors.white};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 28px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 28px;
  `)}

  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const BtnText = styled.span`
  line-height: 26px;
  font-weight: 700;
  //margin-left: 18px;
  font-size: 32px;

  color: ${colors.white};

  ${media.laptop(css`
    font-size: 25px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    font-size: 18px;
    line-height: 26px;
  `)}

  ${media.mobile(css`
    font-size: 16px;
    line-height: 26px;
  `)}
`;

const Component = styled.div<{
  isVisible?: boolean;
  isSettings?: boolean;
  background?: string;
}>`
  position: relative;
  width: 100%;
  height: 637.61px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.background ? `${props.background}` : `${colors.yellow100}`};
  clip-path: polygon(0% 60px, 100% 0, 100% 100%, 0% 100%);

  ${media.laptop(css`
    clip-path: polygon(0% 60px, 100% 0, 100% 100%, 0% 100%);
  `)}

  ${media.tablet(css`
    clip-path: polygon(0% 38px, 100% 0, 100% 100%, 0% 100%);
    height: 428.5px;
  `)}

  ${media.mobile(css`
    clip-path: polygon(0% 32px, 100% 0, 100% 100%, 0% 100%);
    height: 448.5px;
  `)}

  ${media.mobileSmall(css`
    clip-path: polygon(0% 32px, 100% 0, 100% 100%, 0% 100%);
    height: 488.5px;
  `)}

  ${(props) =>
    props.isSettings
      ? css`
          margin-top: -102px;

          ${media.laptop(css`
            margin-top: -62px;
          `)}
        `
      : ''}
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  padding: 130px 50px 120px;
  position: relative;
  display: inline-flex;

  ${media.laptop(css`
    padding: 120px 60px 160px;
  `)}

  ${media.tablet(css`
    padding-bottom: 100px;

    padding-top: 80px;
  `)}

  ${media.mobile(css`
    padding: 130px 0px 120px;
  `)}
`;

export default DownloadPDF;
