import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { ReactComponent as StickerIcon } from '@/assets/svg/sticker.svg';
import { ReactComponent as StickerLargeIcon } from '@/assets/svg/sticker-large.svg';

import Link from '../Link';

type StickerColor = 'yellow' | 'black' | 'lime' | 'orange' | 'red';

type Props = {
  color: StickerColor;
  customColor?: string;
  size?: string;
  label?: string;
  link?: string;
  isDiscount?: boolean;
  isCertificate?: boolean;
  isProduction?: boolean;
  rotate?: string;
  isTarget?: string;
};

function Star({
  color,
  customColor,
  size,
  label,
  isDiscount,
  isCertificate,
  isProduction,
  rotate,
  link,
  isTarget,
}: Props) {
  return (
    <Component color={color} link={link} customColor={customColor}>
      <Inner size={size} isDiscount={isDiscount} isCertificate={isCertificate}>
        {link ? (
          <ComponentLink to={link} target={isTarget ? isTarget : '_self'} />
        ) : null}
        <Background color={color} customColor={customColor}>
          {isDiscount ? <StickerLargeIcon /> : <StickerIcon />}
        </Background>
        {label ? (
          <Text
            color={color}
            isDiscount={isDiscount}
            isCertificate={isCertificate}
            isProduction={isProduction}
            rotate={rotate}
            dangerouslySetInnerHTML={{ __html: label ?? '' }}
          />
        ) : (
          <Text color={color} rotate={rotate}>
            <SubText>СМОТРИ!</SubText> У НАС ОЧЕНЬ ВЫГОДНО!
          </Text>
        )}
      </Inner>
    </Component>
  );
}

const colorMap: Record<StickerColor, string> = {
  yellow: colors.yellow100,
  black: colors.black,
  lime: colors.yellow200,
  orange: colors.orange,
  red: colors.red100,
};

const colorMapHover: Record<StickerColor, string> = {
  yellow: colors.yellowHover100,
  black: colors.black200,
  lime: colors.yellowHover,
  orange: colors.orangeHover,
  red: colors.redHover,
};

const Text = styled.span<{
  color?: string;
  isDiscount?: boolean;
  isCertificate?: boolean;
  isProduction?: boolean;
  rotate?: string;
}>`
  display: block;
  position: relative;
  text-align: center;
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  pointer-events: none;
  transition: all 0.3s linear;
  color: ${(props) =>
    props.color === 'black'
      ? `${colors.yellow200}`
      : props.color === 'orange' || props.color === 'red'
      ? `${colors.white}`
      : `${colors.black}`};
  transform: rotate(${(props) => (props.rotate ? `${props.rotate}` : '0')})
    translate3d(0, 0, 0);
  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tabletSmall(css`
    font-size: 20px;
    line-height: 26px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}

  ${(props) =>
    props.isDiscount
      ? css`
          font-weight: 500;
          font-size: 54px;
          line-height: 64px;

          ${media.laptop(css`
            font-size: 40px;
            line-height: 48px;
          `)}
        `
      : ''}
  
  ${(props) =>
    props.isCertificate
      ? css`
          max-width: 100% !important;
        `
      : ''}
  
    ${(props) =>
    props.isProduction
      ? css`
          font-weight: 500;
          font-size: 30px;
          line-height: 36px;

          ${media.laptop(css`
            font-size: 20px;
            line-height: 26px;
          `)}

          ${media.mobile(css`
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
          `)}
        `
      : ''}
`;

const Inner = styled.div<{
  size?: string;
  isDiscount?: boolean;
  isCertificate?: boolean;
}>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  ${(props) =>
    props.size === 'big'
      ? css`
          ${Text} {
            max-width: 246px;

            ${media.laptop(css`
              max-width: 176px;
            `)}

            ${media.tablet(css`
              max-width: 150px;
              font-size: 16px;
              line-height: 20px;
            `)}

            ${media.mobile(css`
              font-size: 12px;
              line-height: 16px;
            `)}
          }
        `
      : props.size === 'middle'
      ? css`
          width: 340px;
          height: 340px;

          ${media.laptop(css`
            width: 280px;
            height: 280px;
          `)}
        `
      : css`
          width: 316px;
          height: 316px;

          ${media.laptop(css`
            width: 240px;
            height: 240px;
          `)}

          ${media.tabletSmall(css`
            width: 180px;
            height: 180px;
          `)}

          ${media.mobile(css`
            width: 164px;
            height: 164px;
          `)}
        `}

  ${(props) =>
    props.isDiscount
      ? css`
          ${Text} {
            max-width: 360px;

            ${media.tablet(css`
              max-width: 200px;
              font-size: 28px;
              line-height: 34px;
            `)}

            ${media.mobile(css`
              max-width: 140px;
              font-size: 22px;
              line-height: 28px;
            `)}
          }
        `
      : ''}
  
    ${(props) =>
    props.isCertificate
      ? css`
          width: 500px;
          height: 460px;

          ${media.laptop(css`
            width: 440px !important;
            height: 440px !important;
          `)}

          ${media.tablet(css`
            width: 240px !important;
            height: 240px !important;
          `)}
          
          ${media.mobile(css`
            width: 210px !important;
            height: 210px !important;
          `)}
        `
      : ''}
`;

const ComponentLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const Background = styled.div<{
  color: StickerColor;
  customColor?: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s linear;
  color: ${(props) =>
    props.customColor ? props.customColor : colorMap[props.color]};

  svg {
    width: 100%;
    height: 100%;
  }
`;

const SubText = styled.span`
  display: block;
  position: relative;
  text-align: center;
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const Component = styled.div<{
  color: StickerColor;
  link?: string;
  customColor?: string;
}>`
  width: 100%;
  height: 100%;

  ${(props) =>
    props.link
      ? css`
          &:hover {
            ${Background} {
              color: ${props.customColor
                ? props.customColor
                : colorMapHover[props.color]};
            }
          }
        `
      : ''}
`;

export default Star;
